<nz-layout>
  <nz-header>
    <ul nz-menu nzTheme="dark" nzMode="horizontal">
    </ul>
  </nz-header>
  <nz-content>
    <div class="inner-content">
      <router-outlet></router-outlet>
    </div>
  </nz-content>
  <nz-footer>
    RAMP51
  </nz-footer>
</nz-layout>