import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AuthToken } from '../models/AuthToken';

@Injectable({
  providedIn: 'root'
})
export class OAuthLoginGuard implements CanActivate {
  
  constructor(private _authService: AuthService, private _router: Router) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log("entered oauth login guard");

    const authCode: string = route.queryParams.code;

    if (authCode) 
    {  
      return this._authService.exchangeCodeForToken(authCode).pipe(
        map((response: AuthToken) => {
          return this._router.createUrlTree(["dashboard"]);;
        })
      );
    }
    else 
    {
      return this._router.createUrlTree(["dashboard"]);
    }
  }
  
}
