// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  name: "local",
  production: false,
  loanRailsApiDomain: "localhost:3001",
  authConfig: {
    region: "us-east-1",
    userPoolId: "us-east-1_uWAFBtOoK",
    userPoolWebClientId: "1h3gvnoj08qh4h1bf1o35g4dg1",
    mandatorySignIn: false,
    oauth: {
      domain: "idp.map.dev.ramp51.com",
      scope: [
        "phone", 
        "email", 
        "profile", 
        "openid",
        "aws.cognito.signin.user.admin",
        "https://api.dev.loanrails.com/ipp.admin",
        "https://api.dev.loanrails.com/service.default"
      ],
      redirectSignIn: "http://localhost:4400/oauth2/login",
      redirectSignOut: "http://localhost:4400/dashboard",
      responseType: 'code'
    }
  
  },

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
