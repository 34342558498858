import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { environment as env } from "../../../environments/environment";
//import { CognitoUser } from '@aws-amplify/auth';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthToken } from '../models/AuthToken';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _client: HttpClient, private _router: Router) { }

  public getAccessToken(): Observable<string> {
    let payload: any = this.getToken();

    if (payload) {
      let accessToken: string = payload.access_token;
      let result = of(accessToken);
      //console.log("access token: ", accessToken);
      return result;
    }
    else {
      // redirect to a protected route to force cognito redirect
      this._router.navigateByUrl("dashboard");
      return of("");
    }

   
  }


  public getRefreshToken(): string {
    const refreshToken: string = this.getToken().refresh_token;
    
    //let result = of(refreshToken);
    return refreshToken;
  }


  public getUserSubject(): Observable<string> {
    let payload: any = this.getToken();
    let idToken: string = payload.id_token;
    let decodedToken: any = jwt_decode(idToken);
    let userSubjectId = decodedToken.sub;
    //console.log("user subject id: ", userSubjectId);
    return of(userSubjectId);
  }


  public getUserEmail() {
    let payload: any = this.getToken();
    let idToken: string = payload.id_token;
    let decodedToken: any = jwt_decode(idToken);
    let email = decodedToken.email;
    //console.log("email: ", email);
    return of(email);
  }


  public getUsername() {
    let payload: any = this.getToken();
    let idToken: string = payload.id_token;
    let decodedToken: any = jwt_decode(idToken);
    let username = decodedToken["cognito:username"];
    return of(username);
  }

  public isAuthenticated(): Observable<boolean> {
    let payload: any = this.getToken();
    if (payload) {
      return of(true);
    }
    else {
      return of(false);
    }
    
  }

  public getCurrentAuthenticatedUser(): Observable<null> {
    return of(null);
  }

  public getCognitoLoginUrl() {
    let domain = env.authConfig.oauth.domain;
    let clientId = env.authConfig.userPoolWebClientId;
    let redirectUrl = env.authConfig.oauth.redirectSignIn;
    let scope = env.authConfig.oauth.scope.join(" ");
    let responseType = env.authConfig.oauth.responseType;
    let url = `https://${domain}/login?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scope}`;
    let encodedUrl = encodeURI(url);
    return encodedUrl;
  }

  public getCognitoLogoutUrl() {
    let domain = env.authConfig.oauth.domain;
    let clientId = env.authConfig.userPoolWebClientId;
    let logoutUri = env.authConfig.oauth.redirectSignOut;
    let url = `https://${domain}/logout?client_id=${clientId}&logout_uri=${logoutUri}`;
    let encodedUrl = encodeURI(url);
    console.log("logout url: ", encodedUrl);
    return encodedUrl;
  }


  public logout() {
    console.log("logging out");
    localStorage.removeItem("token_payload");
    window.location.href = this.getCognitoLogoutUrl();
  }








  public exchangeCodeForToken(code: string): Observable<AuthToken> {
    let domain: string = env.authConfig.oauth.domain;
    let redirectUri = env.authConfig.oauth.redirectSignIn;
    let clientId = env.authConfig.userPoolWebClientId;
    const url = `https://${domain}/oauth2/token`;
    const body = new HttpParams()
      .set("grant_type", "authorization_code")
      .set("code", code)
      .set("client_id", clientId)
      .set("redirect_uri", redirectUri);
    
    const headers = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded");

    const response = this._client.post<AuthToken>(url, body, { headers: headers });
    
    return response.pipe(
      tap((authToken: AuthToken) => console.log("exchange code for token response: ", authToken)),
      tap((authToken: AuthToken) => this.saveToken(authToken))
    );

  }


  public saveToken(token: AuthToken) {
    const bufferInSeconds: number = 15;
    const expiration = (token.expires_in - bufferInSeconds) * 1000;
    //let expiration: number = 300 * 1000; // 5 minutes
    localStorage.setItem("token_payload", JSON.stringify(token));
    setTimeout(() => {
      console.log("deleting token from local storage");
      this.logout();
    }, expiration);

  }

  public getToken(): AuthToken {
    const val: string | null = localStorage.getItem("token_payload");



    let token: AuthToken | null = null;

    if (val)
    {
      
      
      //console.log("Date.now(): ", Date.now());
      
      token = JSON.parse(val) as AuthToken;
      const decoded = jwt_decode(token.access_token) as any;
      //console.log("exp (milliseconds): ", decoded.exp * 1000);
      //console.log("exp: ", decoded.exp);
      if (Date.now() > (decoded.exp * 1000)) 
      {
        //console.log("pass 2");
        this.logout();
      }
    }
    else
    {
      this.logout();
    }

    return token as AuthToken;
  }

  



}
