import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { OAuthLoginGuard } from './core/guards/oauth-login.guard';
import { AuthorizedComponent } from './pages/authorized/authorized.component';
import { ProtectedComponent } from './pages/protected/protected.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/welcome' },
  { path: 'welcome', loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule) },
  { path: "protected", component: ProtectedComponent, canActivate: [AuthGuard] },
  { path: "authorized", component: AuthorizedComponent },
  { path: "unauthorized", component: UnauthorizedComponent },
  {
    path: "oauth2/login",
    canActivate: [OAuthLoginGuard],
    children: []
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
